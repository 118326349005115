import AnnouncementAlert from './dashboard/AnnouncementAlert.jsx'
import Logo from './Logo.jsx'
import Orbs from './Orbs.jsx'
import PropTypes from 'prop-types'
import { ProgressBarCss } from './toast/ToastWrapper.jsx'
import { Link } from 'react-router-dom'
import _kebabCase from 'lodash/kebabCase'

export default function Layout ({
  title = '',
  children,
  loading = false,
  logoUrl,
  branding = false,
  announcement = false,
}) {
  return (
    <div
      className="relative min-h-screen flex flex-col items-center justify-center"
      data-label={'layout-' + _kebabCase(title)}
    >
      <div className="mt-12">
        { logoUrl ? <img src={logoUrl} className="h-12 mb-8 mx-auto max-w-[14rem]" /> : <Logo className="mb-8 mx-auto scale-110" />}
        <div className="p-8 rounded-lg w-[30rem] max-w-[90vw] mx-auto space-y-6 bg-[#0E0E0E]">
          <h1 className="text-2xl font-semibold text-center">{loading ? 'Loading...' : title}</h1>
          {loading && <ProgressBarCss duration={15000} />}
          {children}
        </div>
      </div>
      { branding && !loading && (
        <div className="text-gray-500 text-sm mt-4">
          <Link to="/signup" className="hover:text-gray-400">
            Create a
            <img src="/favicon.ico" height={16} width={16} className="inline mb-1 pr-1" />
            Rundown Studio account
          </Link>
        </div>
      )}
      {announcement && (
        <div className="max-w-screen-lg px-12">
          <AnnouncementAlert
            location="login"
            className="text-base mb-8 mt-12"
          />
        </div>
      )}
      <Orbs />
    </div>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  loading: PropTypes.bool,
  logoUrl: PropTypes.string,
  branding: PropTypes.bool,
  announcement: PropTypes.bool,
}
